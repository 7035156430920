.hero-container {
    padding: 0;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 30;

    .inner-rocket {
        padding-top: 0;
        text-align: center;

        img {
            width: 90%;
            max-width: 711px;
        }
    }
}

.toolbar {
    color: #fff;
    background: url(../img/dark-dotted-2.png), #111;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 20px;
}
