.callout {
    padding: 12px 20px;
    margin: 20px 0;
    border: 1px solid #f7f7f9;
    border-top-width: 2px;
    border-radius: 2px;
    box-shadow: 0 1px 5px 0 rgba(50, 50, 50, .05);

    h2, h3, h4, h5, h6 {
        text-transform: uppercase;
        font-size: 1.5em;
        margin-top: 10px;
    }
}

.callout-success {
    border-top-color: #36d678;

    h2, h3, h4, h5, h6, a {
        color: #36d678;
    }
}

.callout-info {
    border-top-color: #5bc0de;

    h2, h3, h4, h5, h6, a {
        color: #5bc0de;
    }
}

.callout-warning {
    border-top-color: #f0ad4e;

    h2, h3, h4, h5, h6, a {
        color: #f0ad4e;
    }
}

.callout-danger {
    border-top-color: #f36;

    h2, h3, h4, h5, h6, a {
        color: #f36;
    }
}
