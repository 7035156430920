body {
    padding-top: 0;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    line-height: 2em;
    font-weight: 300;
    color: #666;
    -webkit-font-smoothing: antialiased;
}

p, .p {
    margin: 0 0 10px;
}

section {
    margin-bottom: 20px;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: 'Raleway', sans-serif;
    color: #000;
    letter-spacing: 2px;
    margin: 24px 0 16px;
    font-weight: 500;
    line-height: 1.1;
}

h1, .h1 {
    font-size: 48px;
}

h2, .h2 {
    font-size: 38px;
    line-height: 48px;
}

h3, .h3 {
    font-size: 28px;
    line-height: 38px;
}

h4, .h4 {
    font-size: 22px;
}

h5, .h5 {
    font-size: 18px;
    line-height: 30px;
}

h6, .h6 {
    font-size: 15px;
    line-height: 22px;
}

a {
    color: #444;
}

a:hover {
    color: #1BBC9B;
    text-decoration: none;
}

a:focus {
    outline: none;
}

a:active, a:focus {
    text-decoration: none;
}

hr {
    width: 50px;
    border: solid 2px #e7e7e7;
    padding: 0;
    margin-top: 0;
}

.title {
    margin-top: 50px;
    color: #777;
    margin-bottom: 15px;
    text-align: center;
}

.margin-bottom {
    margin-bottom: 65px;
}

.text-middle {
    display: inline-block;
    vertical-align: middle;
    float: none;
}
