footer {
    background-color: #2c303f;
    text-align: center;
    padding-top: 60px;
    padding-bottom: 60px;
    border-top: 20px solid #405a74;
    margin-top: 100px;
}

@media (max-width:767px) {
    footer {
        border-width: 6px;
        padding-top: 16px;
        padding-bottom: 16px;
    }
}

.footer-tail {
    background-color: #162029;
    padding-top: 32px;
    padding-bottom: 32px;
}

.footer-tail p {
    margin: 0;
    padding: 0;
    color: #3a536b;
    // font-size: 15px;
}

.footer-tail ul {
    padding: 0;
    margin: 0;
    // font-size: 15px;
}

.footer-tail ul li a {
    color:#3a536b;
}

.footer-tail ul li a:hover {
    color:#1BBC9B;
    text-decoration: none;

}
.footer-tail .border-right {
    border-right: 1px solid #3a536b;
    padding-right: 12px;
}

@media (max-width:767px) {
    .footer-tail .copyright {
        text-align: center;
        margin-bottom: 16px;
    }

    .footer-tail ul {
        text-align: center;
        float: none !important;
    }
}
