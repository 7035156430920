//
// Tables
// --------------------------------------------------

.codex-content {
    // Baseline styles

    table {
        width: 100%;
        max-width: 100%;
        margin-bottom: $line-height-computed;
        border: 1px solid #f7f7f9;
        box-shadow: 0 1px 5px 0 rgba(50, 50, 50, 0.05);
        border-radius: 2px;

        // Cells
        > thead,
        > tbody,
        > tfoot {
            > tr {
                > th,
                > td {
                    padding: $table-cell-padding;
                    line-height: $line-height-base;
                    vertical-align: top;
                    border-top: 2px solid #f7f7f9;
                }
            }
        }

        > tbody > tr:nth-of-type(odd) {
            background-color: $table-bg-accent;
        }

        // Bottom align for column headings
        > thead > tr > th {
            vertical-align: bottom;
            border-bottom: 2px solid #f7f7f9;
        }
        // Remove top border from thead by default
        > caption + thead,
        > colgroup + thead,
        > thead:first-child {
            > tr:first-child {
                > th,
                > td {
                    border-top: 0;
                }
            }
        }

        > thead > tr {
            > th,
            > td {
                border-bottom-width: 2px;
            }
        }

        // Account for multiple tbody instances
        > tbody + tbody {
            border-top: 2px solid #f7f7f9;
        }

        // Nesting
        table {
            background-color: $body-bg;
        }

        overflow-x: auto;
        min-height: 0.01%; // Workaround for IE9 bug (see https://github.com/twbs/bootstrap/issues/14837)

        @media screen and (max-width: $screen-xs-max) {
            width: 100%;
            margin-bottom: ($line-height-computed * 0.75);
            overflow-y: hidden;
            -ms-overflow-style: -ms-autohiding-scrollbar;
            border: 1px solid #f7f7f9;

            // Tighten up spacing
            > .table {
                margin-bottom: 0;

                // Ensure the content doesn't wrap
                > thead,
                > tbody,
                > tfoot {
                    > tr {
                        > th,
                        > td {
                            white-space: nowrap;
                        }
                    }
                }
            }
        }
    }
}
