//
// Variables
// --------------------------------------------------

//
//== General
//
$font-family-body: "Lato", sans-serif;
$font-family-title: Raleway, sans-serif;
$font-family-subtitle: "Montserrat", sans-serif;
$font-family-code: Inconsolata, Monaco, Consolas, 'Courier New', monospace;

//
//== Colors
//
$color-gray-dark:           #373a3c !default;
$color-gray:                #55595c !default;
$color-gray-light:          #818a91 !default;
$color-gray-lighter:        #eceeef !default;
$color-gray-lightest:       #f7f7f9 !default;

$color-primary:             #405a74 !default;
$color-success:             #36d678 !default;
$color-info:                #5bc0de !default;
$color-warning:             #f0ad4e !default;
$color-danger:              #ff3366 !default;
$color-dark:                #000000 !default;
$color-white:               #ffffff !default;

$brand-primary:             $color-primary;
$brand-success:             $color-success;
$brand-info:                $color-info;
$brand-warning:             $color-warning;
$brand-danger:              $color-danger;

$bg-dark:                   #161b1f !default;
$bg-dark-light:             #32333a !default;
$bg-dark-lighter:           #46474d !default;
$bg-dark-lightest:          #69696a !default;
$bg-gray:                   #f0f0f0 !default;
$bg-gray-light:             #f8f9fb !default;
$bg-gray-lighter:           #fafafa !default;
$bg-gray-lightest:          #fdfdfd !default;
$bg-blue:                   #89c1e4 !default;
$bg-orange:                 #fe7c60 !default;
$bg-red:                    #fc6c6c !default;
$bg-purple:                 #968eee !default;
$bg-green:                  #5fd79a !default;


$color-text:                #606873 !default;
$color-text-alt:            #eeeeee !default;
$color-title:               #453c34 !default;
$color-subtitle:            #96a2b2 !default;
$color-subtitle-light:      #c8d0d7 !default;

$color-hr:                  #bbbbbb !default;
$color-hr-alt:              #46474d !default;

$color-border:              #eeeeee !default;
$color-border-input:        #e4eaec !default;
//
//== Spacing
//
$spacer:                     16px !default;
$spacer-x:                   $spacer !default;
$spacer-y:                   $spacer !default;

//
// Timing
//
$duration-instantly:   0s;
$duration-immediately: 0.05s;
$duration-quickly:     0.1s;
$duration-promptly:    0.2s;
$duration-slowly:      0.4s;
$duration-paused:      3.2s;



//
// Sidebar
//
$sidebar-bg-color: #263238;

// Code
$code-inline-color:                 #f4645f;
$code-inline-bg-color:              #f0f2f1;

$code-gray-color:                   #cccccc;
$code-line-bg-color:                #f7f7f7;

$code-window-bg-color:              #ebebeb;
$code-window-border-top-color:      #eaeae9;
$code-window-border-bottom-color:   #dfdfde;
$code-window-circle-red-bg:         #fc615c;
$code-window-circle-red-border:     #fd504a;
$code-window-circle-yellow-bg:      #fec041;
$code-window-circle-yellow-border:  #f0b542;
$code-window-circle-green-bg:       #33c849;
$code-window-circle-green-border:   #1bc634;
$code-window-active-color:          #29b6f6;
