/*

Atom One Light by Daniel Gamage
Original One Light Syntax theme from https://github.com/atom/one-light-syntax

base:    #fafafa
mono-1:  #383a42
mono-2:  #686b77
mono-3:  #a0a1a7
hue-1:   #0184bb
hue-2:   #4078f2
hue-3:   #a626a4
hue-4:   #50a14f
hue-5:   #e45649
hue-5-2: #c91243
hue-6:   #986801
hue-6-2: #c18401

*/

.codex-content img {
    max-width: 100%;
    height: auto;
}

.code-window {
    border: 1px solid #eceeef;
    border-radius: 3px;
    margin: 30px 0;
    padding: 0;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .07);
    background: #fafafa;

    .window-bar {
        height: 38px;
        border-top: 1px solid #eaeae9;
        border-bottom: 1px solid #dfdfde;
        background: #ebebeb;

        .circles {
            margin: 10px 12px 0;
            float: left;

            .circle {
                display: inline-block;
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background-color: #fff;
                border: 1px solid #fff;
            }

            .circle-red {
                background-color: #fc615c;
                border-color: #fd504a;
            }

            .circle-yellow {
                background-color: #fec041;
                border-color: #f0b542;
            }

            .circle-green {
                background-color: #33c849;
                border-color: #1bc634;
            }
        }
    }
}

.hljs {
  display: block;
  overflow-x: auto;
  padding: 12px;
  color: #383a42;
  background: #fafafa;
}

.hljs-comment,
.hljs-quote {
  color: #a0a1a7;
  font-style: italic;
}

.hljs-doctag,
.hljs-keyword,
.hljs-formula {
  color: #a626a4;
}

.hljs-section,
.hljs-name,
.hljs-selector-tag,
.hljs-deletion,
.hljs-subst {
  color: #e45649;
}

.hljs-literal {
  color: #0184bb;
}

.hljs-string,
.hljs-regexp,
.hljs-addition,
.hljs-attribute,
.hljs-meta-string {
  color: #50a14f;
}

.hljs-built_in,
.hljs-class .hljs-title {
  color: #c18401;
}

.hljs-attr,
.hljs-variable,
.hljs-template-variable,
.hljs-type,
.hljs-selector-class,
.hljs-selector-attr,
.hljs-selector-pseudo,
.hljs-number {
  color: #986801;
}

.hljs-symbol,
.hljs-bullet,
.hljs-link,
.hljs-meta,
.hljs-selector-id,
.hljs-title {
  color: #4078f2;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}

.hljs-link {
  text-decoration: underline;
}
