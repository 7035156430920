// Mixins
// --------------------------------------------------

// Common
@mixin media-down($point) {

  $bp-smallphone: "(max-width: 480px)";
  $bp-phone: "(max-width: 767px)";
  $bp-tablet: "(max-width: 991px)";
  $bp-desktop: "(max-width: 1199px)";

  @if $point == smallphone {
    @media #{$bp-smallphone} { @content; }
  }
  @else if $point == phone {
    @media #{$bp-phone} { @content; }
  }
  @else if $point == tablet {
    @media #{$bp-tablet} { @content; }
  }
  @else if $point == desktop {
    @media #{$bp-desktop}  { @content; }
  }

}

@mixin media-up($point) {

  $bp-smallphone: "(min-width: 480px)";
  $bp-phone: "(min-width: 768px)";
  $bp-tablet: "(min-width: 992px)";
  $bp-desktop: "(min-width: 1200px)";

  @if $point == smallphone {
    @media #{$bp-smallphone} { @content; }
  }
  @else if $point == phone {
    @media #{$bp-phone} { @content; }
  }
  @else if $point == tablet {
    @media #{$bp-tablet} { @content; }
  }
  @else if $point == desktop {
    @media #{$bp-desktop}  { @content; }
  }

}


// Utilities
@mixin text-cols($number) {
  column-count: $number;
  column-gap: 24px;
  -webkit-column-count: $number;
  -webkit-column-gap: 24px;
}

// _alerts
@mixin callout-variation($color) {
  border-top-color: $color;

  p:last-child {
    margin-bottom: 0;
    font-size: 15px;
  }

  h5,
  h4,
  a {
    color: $color;
  }

  h5,
  h4 {
    text-transform: uppercase;
  }
}


// _buttons
@mixin btn-variation($color) {
  background-color: $color;
  border-color: $color !important;

  &:hover,
  &:focus,
  &:active {
    color: #fff;
    background-color: lighten($color, 2%);
  }
}


// _jumbotron
@mixin jumbotron-variation($bg-color, $color: $color-white) {
  background-color: $bg-color;
  color: $color;
}
