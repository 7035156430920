.media {
    span {
        font-size: 12px;
        margin: 0;
        padding: 0;
        line-height: 1;
    }

    .media-heading {
        margin: 0;
        padding: 0;
    }

    p {
        font-size: 16px;
        margin: 0;
        padding: 0;
    }
}

.border-clear {
    border: 0;
}

.border-right {
    border-right: 1px solid #eee;
}

.border-left {
    border-left: 1px solid #eee;
}

.border-bottom {
    border-bottom: 1px solid #eee;
    margin-top: 40px;
    margin-bottom: 40px;
    width: 100%;
}

.sidebar > ul,
.sidebar > ul > li > ul > li > ul,
.no-listing {
    list-style: none;
    padding: 0;
    margin: 0;
}

.sidebar > ul > li {
    font-size: 16px;
    font-weight: 700;
    padding: 0 0 10px;
    margin: 1em 0 0;
}

.sidebar > ul > li > ul {
    border-top: 1px dashed rgba(0, 0, 0, .1);
    display: block;
    list-style: none;
    margin: .5em 0 0;
    padding: .5em 0 0;
    font-size: 14px;
}

.sidebar a {
    font-size: 14px;
    font-weight: 400;
}
