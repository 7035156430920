.header-area {
    padding: 30px 0;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    background: 0 0;
    -webkit-transition: all .5s ease 0s;
    -moz-transition: all .5s ease 0s;
    -ms-transition: all .5s ease 0s;
    transition: all .5s ease 0s
}

.header-area.navbar-fixed-top {
    position: fixed;
    padding: 10px 0;
    background: rgba(0, 0, 0, .9);
    -webkit-transition: all .5s ease 0s;
    -moz-transition: all .5s ease 0s;
    -ms-transition: all .5s ease 0s;
    transition: all .5s ease 0s
}

.logo-area {
    padding: 0;
    margin: 0;
    float: left;
    text-align: left;
    font-family: Montserrat, sans-serif;
    position: relative
}

.logo-area a.logo {
    padding-right: 10px;
    display: inline-table;
    text-decoration: none
}

.logo-area span.phone {
    padding-left: 20px;
    display: inline-table;
    color: #82848a;
    font-size: 14px;
    border-left: 1px solid rgba(251, 251, 251, .5)
}

.toggle-btn,
.toggle-btn:focus,
.toggle-btn:hover {
    padding: 0;
    margin: 0;
    float: right;
    position: absolute;
    top: 9px;
    right: 5px;
    color: #fff;
    font-size: 24px;
    cursor: pointer;
    display: none
}

.nav-main {
    padding: 6px 0 0;
    margin: 0;
    float: right;
    text-align: right;
    font-family: Montserrat, sans-serif
}

.nav-main .menu-cont {
    padding: 0;
    margin: 0;
    list-style: none;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase
}

.nav-main .menu-cont li {
    padding: 0 15px;
    margin: 0;
    display: inline-table;
    position: relative
}

.nav-main .menu-cont li a {
    color: #fff;
    text-decoration: none;
    display: block;
    border-bottom: 3px solid rgba(53, 143, 162, 0)
}

.nav-main .menu-cont li a:hover,
.nav-main .menu-cont li.active a,
.nav-main .menu-cont li:hover a {
    color: #1e9fb4;
    text-decoration: none;
    border-bottom: 3px solid rgba(53, 143, 162, .8)
}

a.nav-order-btn,
a.nav-order-btn:hover {
    padding: 3px 10px;
    margin: 0;
    display: inline-table;
    background: #f20707;
    color: #fff!important;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    border: 0!important
}

.nav-main .menu-cont li ul.sub-menu {
    padding: 0;
    margin: 0;
    list-style: none;
    position: absolute;
    display: none
}

.nav-main .menu-cont li:hover ul.sub-menu {
    display: block
}

.nav-main .menu-cont li ul.sub-menu li {
    padding: 0;
    margin: 0;
    display: block;
    text-align: center
}

.nav-main .menu-cont li ul.sub-menu li a {
    padding: 5px 10px;
    margin: 0 0 1px;
    border: 0;
    background: rgba(53, 143, 162, .8);
    white-space: nowrap;
    font-size: 14px;
    font-weight: 400;
    text-transform: none;
    min-width: 130px;
    color: #fff
}

.nav-main .menu-cont li ul.sub-menu li a:hover,
.nav-main .menu-cont li ul.sub-menu li.active a {
    background: rgba(53, 143, 162, .4);
    color: #fff
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

#particles {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: #2c303f;
    position: relative;
    z-index: 10;
    min-height: 850px
}

.cont-area {
    position: absolute;
    left: 0;
    top: 25%;
    padding: 0 20px;
    width: 100%;
    text-align: center;
    height: auto!important;
    z-index: 30
}

.banner-area {
    text-align: center
}

.banner-area h1 {
    font-size: 72px;
    color: #fff
}

.banner-area h5 {
    color: #1e9fb4
}

#particles.inner-particle {
    min-height: 450px
}

.cont-area-inner {
    padding: 0;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 30
}

.cont-area-inner h1 {
    padding: 0;
    margin: 0;
    color: #fff
}

.cont-area-inner .inner-rocket-img {
    padding-top: 120px;
    text-align: center
}

.cont-area-inner .inner-rocket-img img {
    width: 90%;
    max-width: 711px
}

.cont-area-inner .heading-area {
    padding: 35px 0;
    margin: 0;
    background: rgba(0, 0, 0, .3)
}

.banner-btm-img {
    padding: 0;
    margin: 0;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 10
}

.banner-btm-img img {
    width: 100%;
    max-width: 1100px
}

@media (max-width:1100px) {
    .logo-area,
    .nav-main {
        float: none;
        width: 100%;
        text-align: center
    }
}

@media (max-width:991px) {
    .banner-area h1 {
        font-size: 50px
    }

    .cont-area {
        top: 35%
    }

    .cont-area-inner .inner-rocket-img {
        padding-top: 0
    }

    .cont-area-inner .heading-area {
        padding: 15px 0;
        text-align: center
    }
}

@media (max-width:480px) {
    .header-area {
        padding: 10px 0;
        position: inherit;
        top: inherit;
        left: inherit;
        background: rgba(0, 0, 0, 1)
    }

    .header-area.navbar-fixed-top {
        position: inherit;
        padding: 10px 0;
        background: rgba(0, 0, 0, 1)
    }

    .logo-area {
        text-align: left
    }

    .nav-main {
        display: none
    }

    .nav-main .menu-cont li {
        padding: 10px 0;
        width: 100%
    }

    .nav-main .menu-cont li ul.sub-menu {
        position: inherit
    }

    #particles {
        min-height: 600px
    }

    .cont-area {
        top: 10%
    }

    #particles.inner-particle {
        min-height: 230px
    }
}

@media (max-width:380px) {
    .logo-area {
        text-align: center
    }
}
